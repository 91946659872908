<template>
    <div id="go-gsea">
        <a-descriptions bordered size="small">
            <a-descriptions-item label="GO富集" :span="3">
                <a-badge status="processing" :text="task.task_id" />
            </a-descriptions-item>
            <a-descriptions-item label="比较组">
                <span style="font-weight: bold;">{{ task.meta.dispose }}</span>
                vs
                <span style="font-weight: bold;">{{ task.meta.contrast }}</span>
            </a-descriptions-item>
            <a-descriptions-item label="表格数据下载">
                <a-icon
                    type="download"
                    style="font-size: 1rem;cursor: pointer;width: 100%;"
                    @click="downloadFile(csvUrl)"
                />
            </a-descriptions-item>
        </a-descriptions>

        <a-table
            :columns="columns"
            :data-source="goData"
            :loading="tableLoading"
            rowKey="ID"
            size="small"
            style="margin-top:1.5rem"
            bordered :scroll="{x:true}"
        >
            <div
                slot="filterDropdown"
                slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
                style="padding: 0.5rem;"
            >
                <a-input
                    v-ant-ref="c => (searchInput = c)"
                    :placeholder="`Search ${column.dataIndex}`"
                    :value="selectedKeys[0]"
                    size="small"
                    style="width: 188px; margin-bottom: 8px; display: block;"
                    @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                    @pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
                />
                <a-button
                    type="primary"
                    icon="search"
                    size="small"
                    style="width: 90px; margin-right: 8px"
                    @click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
                >Search</a-button>
                <a-button
                    size="small"
                    style="width: 90px"
                    @click="() => handleReset(clearFilters)"
                >Reset</a-button>
            </div>
            <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template slot="customRender" slot-scope="text, record, index, column">
                <span v-if="searchText && searchedColumn === column.dataIndex">
                    <template
                        v-for="(fragment, i) in text
                        .toString()
                        .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                    >
                        <mark
                            v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                            :key="i"
                            class="highlight"
                        >{{ fragment }}</mark>
                        <template v-else>{{ fragment }}</template>
                    </template>
                </span>
                <template v-else>{{ text }}</template>
            </template>
        </a-table>

        <div class="pdf-list">
            <pdf v-for="pdf in pdfUrl" :key="pdf" :src="pdf" class="pdf"></pdf>
        </div>
    </div>
</template>

<script>
import { parseCsv, downloadFile } from "@/utils/csv.js"
import pdf from 'vue-pdf'

export default {
    name: "GoGsea",
    components: {
        pdf
    },
    data() {
        return {
            filterValue: 0.05, //筛选标准
            csvUrl: "",
            pdfUrl:[],
            goData: [], //表格数据
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'ID',
                    width: 110,
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'customRender'
                    },
                    onFilter: (value, record) => record.ID.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            });
                        }
                    }
                },
                {
                    title: 'Description',
                    dataIndex: 'Description',
                    ellipsis: true,
                    width: 260,
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'customRender'
                    },
                    onFilter: (value, record) => record.Description.toString().toLowerCase().includes(value.toLowerCase()),
                    onFilterDropdownVisibleChange: visible => {
                        if (visible) {
                            setTimeout(() => {
                                this.searchInput.focus();
                            });
                        }
                    }
                },
                {
                    title: 'setSize',
                    dataIndex: 'setSize',
                    width: 80
                },
                {
                    title: 'enrichmentScore',
                    dataIndex: 'enrichmentScore',
                    width: 150,
                    sorter: (a, b) => a.enrichmentScore - b.enrichmentScore,
                    customRender: (text, row, index) => text.toFixed(3),
                },
                {
                    title: 'NES',
                    dataIndex: 'NES',
                    sorter: (a, b) => a.NES - b.NES,
                    customRender: (text, row, index) => text.toFixed(3),
                    width: 90
                },
                {
                    title: 'pvalue',
                    dataIndex: 'pvalue',
                    sorter: (a, b) => a.pvalue - b.pvalue,
                    customRender: (text, row, index) => text.toExponential(3),
                    width: 100
                },
                {
                    title: 'p.adjust',
                    dataIndex: 'p.adjust',
                    sorter: (a, b) => a['p.adjust'] - b['p.adjust'],
                    customRender: (text, row, index) => text.toFixed(3),
                    width: 100
                },
                {
                    title: 'qvalues',
                    dataIndex: 'qvalues',
                    sorter: (a, b) => a.qvalues - b.qvalues,
                    customRender: (text, row, index) => text.toFixed(3),
                    width: 100
                },
                {
                    title: 'rank',
                    dataIndex: 'rank',
                    width: 60
                },
                {
                    title: 'leading_edge',
                    dataIndex: 'leading_edge',
                    width: 260
                },
                {
                    title: 'core_enrichment',
                    dataIndex: 'core_enrichment',
                    ellipsis: true,
                    customRender: (text, row, index) => text.split("/").join(",")
                }
            ], //表格表头数据
            /* 表格搜索 */
            searchText: '',
            searchInput: null,
            searchedColumn: '',
            /* 表格搜索 End */
            tableLoading: true,
        };
    },
    props: {
        task: {
            type: Object,
            default: {}
        }
    },
    mounted() {
        this.csvUrl = this.common.source_url + "/" + this.task.result.path + "/data.GO_gsea.csv";
        this.pdfUrl.push(this.common.source_url + "/" + this.task.result.path + "/datagseGO.heatmap.pdf");
        this.pdfUrl.push(this.common.source_url + "/" + this.task.result.path + "/datagseGO.pdf")

        parseCsv(this.csvUrl, (csv) => {
            this.goData = csv;
            this.tableLoading = false;
        })
    },
    methods: {
        downloadFile,
        //表格搜索
        handleSearch(selectedKeys, confirm, dataIndex) {
            confirm();
            this.searchText = selectedKeys[0];
            this.searchedColumn = dataIndex;
        },
        //重置搜索
        handleReset(clearFilters) {
            clearFilters();
            this.searchText = '';
        },
    },
}
</script>

<style lang="scss" scoped>
</style>