<template>
    <div class="enrichment">
        <div class="enrichment-task-id">
            Job id：{{ task.task_id }}
        </div>
        <a-tabs default-active-key="GOBP" class="enrichment-tabs">
            <a-tab-pane key="GOBP" tab="GO-BP富集"  v-if="isHasFile('data.GO_BP.enrichment.csv')">
                <GoBPEnrichment :task="task" />
            </a-tab-pane>
            <a-tab-pane key="GOMF" tab="GO-MF富集" v-if="isHasFile('data.GO_MF.enrichment.csv')">
                <GoMFEnrichment :task="task" />
            </a-tab-pane>
            <a-tab-pane key="GOCC" tab="GO-CC富集" v-if="isHasFile('data.GO_CC.enrichment.csv')">
                <GoCCEnrichment :task="task" />
            </a-tab-pane>
            <a-tab-pane key="KEGG" tab="KEGG富集" force-render v-if="isHasFile('data.kegg.enrichment.csv')">
                <KeggEnrichment :task="task" />
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import GoMFEnrichment from "@/components/Enrichment/GoMFEnrichment.vue";
import GoBPEnrichment from "@/components/Enrichment/GoBPEnrichment.vue";
import GoCCEnrichment from "@/components/Enrichment/GoCCEnrichment.vue";
import KeggEnrichment from "@/components/Enrichment/KeggEnrichment.vue";

export default {
    name: "Enrichment",
    components: {KeggEnrichment, GoCCEnrichment, GoBPEnrichment, GoMFEnrichment},
    data() {
        return {
            task: {}
        }
    },
    created() {
        this.task = this.common.getLocalStorageItem("current_enrichment_task");
    },
    methods: {
        isHasFile(url){
            return this.task.result.files.indexOf(url) !== -1;
        },
    },
}
</script>

<style scoped lang="scss">
.enrichment{
    padding: 2rem;
}
.enrichment-task-id{
    height: 29px;
    font-size: 21px;
    font-weight: 600;
    color: #333333;
    line-height: 25px;
    margin-bottom: 40px;
}
</style>