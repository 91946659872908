<template>
    <a-tabs default-active-key="gsea-go" class="gsea-tabs">
        <a-tab-pane key="gsea-go" tab="GO"  v-if="isHasFile('data.GO_gsea.csv')">
            <GoGsea :task="task" />
        </a-tab-pane>
        <a-tab-pane key="gsea-kegg" tab="KEGG" v-if="isHasFile('data.KEGG_gsea.csv')">
            <KeggGsea :task="task" />
        </a-tab-pane>
    </a-tabs>
</template>

<script>
import KeggGsea from "@/components/Enrichment/KeggGsea.vue";
import GoGsea from "@/components/Enrichment/GoGsea.vue";

export default {
    name: "Gsea",
    components: {GoGsea, KeggGsea},
    data() {
        return {
            task: {}
        }
    },
    created() {
        this.task = this.common.getLocalStorageItem("current_enrichment_task");
    },
    methods: {
        isHasFile(url){
            return this.task.result.files.indexOf(url) !== -1;
        },
    },
}
</script>

<style scoped>

</style>