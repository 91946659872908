<template>
    <div class="enrichment-analysis">
        <div class="enrichment-analysis-con">
            <div class="enrichment-analysis-con-menu">
                <div :class="['enrichment-analysis-con-menu-item', activeMenu === 'enrichment' && 'enrichment-analysis-con-menu-item-active']"
                    @click="changeMenu('enrichment')">ENRICHMENT</div>
                <div :class="['enrichment-analysis-con-menu-item', activeMenu === 'gsea' && 'enrichment-analysis-con-menu-item-active']"
                     @click="changeMenu('gsea')">GSEA</div>
            </div>

            <div class="enrichment-analysis-con-d"></div>

            <div class="enrichment-analysis-con-main">
                <Enrichment v-if="activeMenu === 'enrichment'" ></Enrichment>
                <Gsea v-else></Gsea>
            </div>
        </div>
    </div>
</template>

<script>
import Enrichment from "../../components/Enrichment/Enrichment.vue";
import Gsea from "../../components/Enrichment/Gsea.vue";

export default {
    name: "LayoutQueryEnrichmentAnalysis",
    components: { Enrichment,Gsea },
    data() {
        return {
            task: {},
            activeMenu: "enrichment",
        }
    },
    methods:{
        changeMenu(menu){
            if(menu === this.activeMenu) return;
            this.activeMenu = menu;
        }
    }
}
</script>

<style lang="scss" scoped>
.enrichment-analysis {
    height: 100%;
    background: #F9F9FB;
    padding: 1.4rem 8rem;

    &-con{
        display: flex;
        background: #fff;

        &-menu{
            width: 12.35rem;

            &-item{
                width: 100%;
                height: 64px;
                line-height: 64px;
                background: #fff;
                font-size: 19px;
                text-align: center;
                color: #666;
                font-weight: 500;
                cursor: pointer;

                &-active{
                    background: #097F35;
                    color: #fff;
                }
            }
        }

        &-d{
            width:0.65rem;
            background: #F9F9FB;
        }

        &-main{
            width: 66rem;
        }
    }
}
</style>